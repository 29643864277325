<template>
  <div class="image-wrapper" v-if="list">
    <div class="image-item" :style="{ width: `${width}rem`, height: `${height}rem` }" v-for="item in list" :key="item">
      <el-image
        :style="{ width: `${width}rem`, height: `${height}rem` }"
        :src="item"
        fit="cover"
        :preview-src-list="list">
        <div slot="placeholder" class="image-slot">
          <div class="inner">
            <i class="el-icon-loading"></i>
            <p>加载中</p>
          </div>
        </div>
        <div slot="error" class="image-slot">
          <div class="inner">
            <i class="el-icon-picture-outline"></i>
            <p>无此图片</p>
          </div>
        </div>
      </el-image>
    </div>
  </div>
</template>
<script>
export default {
  ame: 'Menu',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number | String,
      default: '130'
    },
    height: {
      type: Number | String,
      default: '130'
    }
  }
}
</script>
<style lang="scss" scoped>
.image {
  &-wrapper {
    margin-bottom: -20rem;
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    margin-right: 20rem;
    margin-bottom: 20rem;
  }
  &-slot {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 6rem;
    .inner {
      font-size: 14rem;
      text-align: center;
      display: flex;
      align-items: center;
      i {
        margin-right: 5rem;
        font-size: 20rem;
      }
    }
  }
}
</style>
