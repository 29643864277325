<template>
  <div class="page-wrapper">
    <div class="page-info">
      <div class="page-top-info">
        <el-row class="page-top-line">
          <el-col :span="6">
            <div class="inner">
              <div class="label">班主任姓名</div>
              <div class="content">{{ info.teacher_name }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="inner">
              <div class="label">行政班</div>
              <div class="content">{{ info.class_name }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="inner">
              <div class="label">年级</div>
              <div class="content">{{ info.grade_name }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="inner">
              <div class="label" :span="8">校区</div>
              <div class="content">{{ info.school_name }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="page-card">
        <div class="page-card-title">
          <div class="l"><img src="@/assets/img/z_zaocao.png" alt="" />查早操记录</div>
          <div class="r">提交时间 {{ info.updated_at }}</div>
        </div>
        <div class="page-card-content">
          <div class="inner-box">
            <div class="inner-item">
              <el-row>
                <el-col :span="12">
                  <el-row>
                    <el-col :span="6">今日是否出操</el-col>
                    <el-col :span="18"
                      ><span class="c1 fw">{{ `${info.is_exercise === 1 ? '已' : '未'}出操` }}</span></el-col
                    >
                  </el-row>
                </el-col>
                <el-col :span="12" v-if="info.is_exercise">
                  <el-row>
                    <el-col :span="6">人员是否到齐</el-col>
                    <el-col :span="18"
                      ><span class="c1 fw">{{ `${info.is_complete === 1 ? '已' : '未'}到齐` }}</span></el-col
                    >
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="inner-title">说明</div>
          <div class="inner-box">
            {{ info.description }}
          </div>
          <template v-if="info.is_exercise">
            <div class="inner-title">出操照片</div>
            <div class="inner-box">
              <image-list :list="imgList"></image-list>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="page-tool">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
import imageList from '@/components/ImageList'
export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  name: 'Details',
  components: { imageList },
  data() {
    return {
      info: {},
      imgList: []
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id
      if (!id) return
      this.$_axios2.get('/api/teacher/exercise/record-detail', { params: { id } }).then((res) => {
        this.info = res.data.data
        this.imgList = this.info.photo
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
